.search-input{
    position: relative;
    display: inline-block;

    &__result{
        position: absolute;
        background:white;
        left: 0;
        right: 0;
        padding: $spacing1;
        box-shadow: 0 0 2px $shadow-grey;
        border-radius: $radius2;
        &-item{
            margin-top: 2px;
            padding: $spacing1;
            &:first-child{
                margin: 0;
            }

            &:hover{
                box-shadow: 0 1px 3px $shadow-grey;
            }
        }
    }
}
