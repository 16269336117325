.calendar{
    padding: $spacing2;
    $border-color: black;
    &__header{
        padding: $spacing1 0;
    }
    &__handle{

        &--month{
            padding: $spacing1;
            box-shadow: 0 0 $radius3 $border-color;
            cursor: pointer;    
        }

        &--year{
            padding: $spacing1;
            box-shadow: 0 0 $radius3 $border-color;
            cursor: pointer;
            &:first-child{
                margin-right: $spacing1*4;
            }
            &:last-child{
                margin-left: $spacing1*4;
            }
        }
    }

    &__title{
        font-size: 2rem;
        text-align: center;
        font-weight: bold;
    }
    &__month{
        display: grid;
        grid-template-columns: repeat(7,auto);
        grid-template-rows:  30px repeat(6, 100px);
    }


    

    @for $i from 0 through 6{
        &__day:nth-child(#{$i*7+1}){
            border-left: 2px solid $border-color;
        }
        &__day:nth-child(#{$i*7+7}){
            border-right: 2px solid $border-color;
        }
        &__day:nth-child(#{$i+1}){
            border-top: 2px solid $border-color;
        }
        &__day:nth-child(#{$i+43}){
            border-bottom: 2px solid $border-color;
        }
    }


    &__day{
        box-sizing: border-box;
        border: 1px solid $border-color;
        padding: $spacing1;
        border-radius: $radius5;
        &--label{
            text-align: center;
            font-size: 1.5rem;
            font-weight: bold;
            line-height: 30px;
            padding: 0;
        }

        &--today{
            background-color: darken($color: yellow, $amount: 10);
        }
        &--outbound{
            background-color: darken($color: white, $amount: 10);
            color: darken($color: white, $amount: 40);
        }
    }
    
}