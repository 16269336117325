html{
    font-size: 66.7%;
}

body{
    padding: 0;
    margin: 0;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

@import './abstract/main';
.App{
    display: flex;
    & > :first-child{
        flex: 1;
        min-width: 150px;
    }
    & > :last-child{
        flex: 5;
        // overflow: auto;
    }
    height: calc(100vh - #{$navbar-height});
    .container{
        padding: 0 $spacing2*3;
        max-height: -webkit-fill-available;
        overflow: auto
    }
}

@import './components/main';
@import './pageComponents/main';
@import './containers/main';


