.navbar{
    display: flex;
    align-items: center;
    background: $color-navbar;
    z-index: 10000;
    height: $navbar-height;
    & * {text-decoration: none; cursor: default; color: $color-navbar-text;}

    &__logo{
        display: block;
        padding: $spacing1*3 0; 
        margin: 0 $spacing2*4;
        font-size: 1.2rem;
        &:hover{
            cursor: pointer;
        }
    }

    &__left{}
    &__center{
        flex: 1;
        display: flex;
        justify-content: center;
    }
    &__right{
        display: flex;
        margin-right: $spacing2*4;
    }
}