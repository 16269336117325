.navigation{

    & > .navbar{
        position: sticky;
        top: 0;
    }

    & > :nth-child(3){
        z-index: 1;
    }
}