.side-nav{
    background-color: navy;
    & > ul{
        & li{
            list-style: none;
            & a{
                display: block;
                padding: $spacing1 $spacing2;
                text-decoration: none !important;
                color: white !important;
            }
            &:hover{
                background-color: blueviolet;
            }
        }
    }
}