.login-form{
    display: flex;
    background-color: $color-navbar;
    padding: 2*$spacing2 4*$spacing2;
    border-radius: $radius2;
    box-shadow: 0 0 $spacing2 $color-white;
    // height: 50vh;
    // width: 50vw;
    height: 100vh;
    width: 100vw;
    align-items: center;
    color: $color-navbar-text;

    &__fields{
        display: flex;
        flex-direction: column;
        flex: 1;
        & > .btn-flat{
            margin: $spacing1;
            padding: $spacing1;
        }
    }
    &__border-line{
        box-shadow: 0 0 $spacing2 $color-white;
        height: 95%;
        width: 1px;
        margin: 0 20px;
    }
    &__logo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
    }
}