
$color-black: #0b0b0b;
$color-white: #f6f6f6;
$color-blue: blue;
$color-grey: grey;


$shadow-grey: $color-grey;


$color-navbar: #211D71;
$color-navbar-text: white;