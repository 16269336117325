.minichart{
    display: inline-block;
    border-radius: $radius5;
    box-shadow: 0 0 $radius5*2 $shadow-grey;
    margin: $radius5*2;
    padding: $spacing1;
    padding-bottom: 0;

    &__title{
        font-size: 1.1rem;
        letter-spacing: 2px;
        font-weight: bold;
        text-align: center
    }

    &--color-red{
        background-color: red;
        box-shadow: 0 0 $radius5*2 red;
    }

    &--color-green{
        background-color: green;
        box-shadow: 0 0 $radius5*2 green;
    }

    &--color-purple{
        background-color: purple;
        box-shadow: 0 0 $radius5*2 purple;
    }

    &--color-yellow{
        background-color: yellow;
        box-shadow: 0 0 $radius5*2 yellow;
    }
}

@import './LineWidget';