.notification-send{
    
    text-align: left;


    & .input {
        width: 100%;
    }
    
    & .textarea{
        width: 95%;
    }

    &__to{
        flex: 1
    }

    &--alert.swal2-popup{
        width: 50vw;
    }
}

.notification-send--alert{
    padding: 100px;
    width: 500px;
}