.online-course-asset{
    min-height: 60vh;
    border-radius: 4px;
    box-shadow: 0 0 5px grey;
    padding: 10px;
    margin: 0 10px;
    &__video{
        &-input{
            padding: 10px;
        }

        &-output{
            padding: 10px;
            height: 27rem;
        }
    }
    
    &__math{
        // background-color: red;

    }

   
}