.table{
    display: inline-block;
    // border: 1px solid $color-black;
    padding: $spacing1;
    border-radius: $radius2;
    background-color: whitesmoke;
    box-shadow: 0 0 2px $shadow-grey;
    cursor: default;
    
    &  table{
        & > thead{

            & th{
                background: $color-black;
                color: $color-white;
                padding: $spacing1 2*$spacing1;
                
                &:first-child {
                    border-radius: $radius5 0 0  $radius5;
                }
                &:last-child {
                    border-radius: 0 $radius5 $radius5 0;
                }
            }
        }
    
        & > tbody{   
            & > tr {
                & td {
                    padding: $spacing1 2*$spacing1;
                }
                & td:first-child{
                    padding-left: $spacing1;
                }
                & td:last-child{
                    padding-right: $spacing1;
                }
                &:last-child td:first-child{
                    border-radius: 0 0 0  $radius5;
                }
                &:last-child td:last-child{
                    border-radius: 0 0 $radius5 0;
                }
            } 
    
        }
    }

    & &--hover{
        &--wheat:hover{
           background: wheat; 
        }
    }
}