.accountant.fees-collection{
    
    & .fees-collection{
        &__search-result-item{
            & div:nth-child(1){
                font-size: 1.2rem;
                // font-weight: bold;
            }
            & div:nth-child(2){
                font-size: 0.9rem;
                // font-weight: bold;
            }
        }

    }
}