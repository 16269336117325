

.input-text{
    display: inline-block;
    flex-direction: column;
    &__header{
        margin: 0 $spacing1;
    }
    & input{
        margin: $spacing1;
        border-radius: $radius3;
        outline: none;
        border: 1px solid black;
        padding: $spacing1 $spacing2;
        flex: 1;
    }
    & > :nth-child(2){
        align-items: center;
        justify-content: center;
    }
}