.select{

    display: flex;
    align-items: center;
    // font-size: 1.2rem;
    

    & > &__leading, & > &__trailing{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 $spacing1;
    }

    & > select{
        background-color: $color-white;
        padding: $spacing1;
        flex: 1;
        display: inline-block;
        outline: none;
        border: 1px solid $color-black;
        border-radius: $radius1;
        font-size: 1rem;
    }

    &--white select{ background-color: $color-white; }
    &--blue select{ background-color: $color-blue; color: $color-white;}
  
}