.course-fee-payment{

    &__details{
        display: inline-block;
    }

    &__fee-structure{
        background: $color-grey;
        text-align: center;
        & thead{
            background: $color-white;
            color: $color-navbar-text;
            & th{
                background: $color-navbar;
                padding: $spacing1*2;
            };
        }
        & tbody{
            background: $color-white;
            & td{
                padding: $spacing1;
                &:nth-child(2){
                    text-align: start;
                }
            }
        }
    }

    &__actions{
        margin-top: $spacing1*2;
        display: flex;
        justify-content: space-between;
    }
}