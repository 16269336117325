.notification-tile{
    padding: 2*$spacing1;
    box-shadow: 0 0 2px $shadow-grey;
    margin: $spacing1*2 0;
    
    &__title{font-size: 1.5rem;}
    &__kind{
        font-size: 1rem;
        margin: 0 $spacing1;
    }

    &__description{
        margin: $spacing1 0;
    }

    &__from, &__datetime{
        color: $color-grey;
        font-size: 0.8rem;
    }

}