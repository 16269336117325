.u-flex{
    &--row{
        display: flex;
        flex-direction: row;
    }
    &--collumn{
        display: flex;
        flex-direction: column;
    }

    &--center{
        justify-content: center;
        align-items: center;
        &-main{
            justify-content: center;
        }
        &-cross{
            align-items: center;
        }
    }
    

    &--1{flex: 1}
    &--2{flex: 2}
    &--3{flex: 3}
    &--4{flex: 4}
    &--5{flex: 5}
    &--6{flex: 6}
}