.online-course{
    &__months{
        border-top: 1px solid black ;
        border-bottom: 1px solid black ;
        display: flex;
        &-item{
            display: inline-block;
            padding: $spacing2 $spacing1*4;
            cursor: pointer;
            &:hover{
                background: yellow;
            }
            &--active{
                background: green;
            }
        }
    }

   
}
/* CourseMonth.jsx */
.online-course-month{
    &__weeks{
        border-top: 1px solid black ;
        border-bottom: 1px solid black ;
        display: flex;
        &-item{
            display: inline-block;
            padding: $spacing2 $spacing1*4;
            cursor: pointer;
            &:hover{
                background: yellow;
            }
            &--active{
                background: green;
            }
        }
    }   
}

/* CourseWeek.jsx */
.online-course-week{
    display: flex;
    &__days{
        width: 20%;
        border-top: 1px solid black ;
        border-bottom: 1px solid black ;
        display: flex;
        flex-direction: column;
        &-item{
            display: inline-block;
            padding: $spacing2 $spacing1*4;
            cursor: pointer;
            &:hover{
                background: yellow;
            }
            &--active{
                background: green;
            }
        }
    }   
}


/* CourseWeek.jsx */
.online-course-day{
    display: flex;
    &__assets{
        width: 20%;
        border-top: 1px solid black ;
        border-bottom: 1px solid black ;
        display: flex;
        flex-direction: column;
        &-item{
            display: inline-block;
            padding: $spacing2 $spacing1*4;
            cursor: pointer;
            &:hover{
                background: yellow;
            }
            &--active{
                background: green;
            }
        }
    }   
}