.login-screen{
    position: absolute;
    background-color: rgba($color: $color-black, $alpha: 0.8);
    top: 0;
    left: -200vw;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 4s;
    z-index: -1;
    overflow: hidden;
    
    width: 0;
    &--show{
        left: 0;
        width: 100vw;
        z-index: 100000;
    }

    & .btn-close{
        position: absolute;
        top: 2*$spacing2;
        right: 2*$spacing2;
        height: 1rem;
        width: 1rem;
        border-radius: $radius5;
    }

}